button.btn-base-primary.custom-loggind {
    padding: 1px 10px !important;
}

button.custom-calendly.btn.btn-warning {
    padding: 4px !important;
    font-size: 14px !important;
}

span.custom-cart.badge.rounded-pill.bg-warning {
    position: relative !important;
    top: -8px !important;
    right: 10px !important;
    background: #EAA313 !important;
}

.custom-width {
    width: 25px !important;
}

.navbar-collapse.collapse.show {
    background-color: #343434 !important;
}

a.base-navlink.login {
    padding: 6px !important;
}


img.img-brand {
    width: auto !important;
    height: 75px;
}

.navbar-light .navbar-nav .nav-link {
    color: #8f8a8a !important;
    font-family: "Rubik" !important;
}


.navbar-nav .nav-item {
    padding: 0 3px;
}


.navbar-light .navbar-toggler,
button.navbar-toggler.collapsed {
    margin-right: 6px !important;
    border-color: transparent !important;
}

.offcanvas.offcanvas-start.show {
    width: 250px !important;
    background-color: #343434 !important;
}

.offcanvas.offcanvas-start.show .navbar-nav .dropdown-menu {
    background-color: #343434 !important;
    border: none;
}

.offcanvas.offcanvas-start.show .navbar-nav .dropdown-item {
    font-family: "Rubik" !important;
    color: #fff !important;
    padding: 18px 0px 10px 0px !important;
    border-bottom: 1px solid rgba(155, 155, 155, 0.12) !important;
}

.offcanvas.offcanvas-start.show .navbar-nav .nav-link {
    font-family: "Rubik" !important;
    color: #fff !important;
    padding: 18px 0px 10px 0px !important;
    border-bottom: 1px solid rgba(155, 155, 155, 0.12) !important;
}

.offcanvas.offcanvas-start.show .navbar-nav svg.custom-cart-link {
    color: #fff !important;
}

.offcanvas-header .btn-close {
    color: #fff !important;
}

.offcanvas.offcanvas-start.show li.nav-item {
    padding: 14px 2px !important;
    border-bottom: 1px solid rgba(155, 155, 155, 0.12) !important;
}

.offcanvas.offcanvas-start.show a.base-navlink.login {
    padding: 7px 67px !important;
}

.offcanvas.offcanvas-start.show button.btn-base-primary.custom-loggind {
    padding: 2px 86px !important;
    width: -webkit-fill-available;
}

.offcanvas.offcanvas-start.show button.custom-calendly.btn.btn-warning {
    padding: 4px 47px !important;
}

a.dropdown-item {
    font-family: "Rubik";
}

.nav-item.show.dropdown a.dropdown-item {
    color: #8f8a8a !important;
}

.custom-dropdown-loggin.dropdown-menu.show {
    left: -98px !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #3655b9 !important;
    background-color: transparent !important;
}

#offcanvasNavbar-expand .btn-close {
    background: transparent url(https://reise-assets.fra1.cdn.digitaloceanspaces.com/assets/x.svg) center/1em auto no-repeat !important;
    opacity: 1 !important;
}

.dropdown-switch-langue.rounded-0.dropdown-menu.show {
    min-width: 3.7rem !important;
}

button#dropdown-switch-langue {
    padding: 2px 14px;
}

button#dropdown-switch-currency {
    padding: 2px 10px;
}

li.custom-login.nav-item {
    margin-top: 2px;
    padding: 0 15px;
}


@media (max-width: 768px) {
    a.navbar-brand {
        margin-left: 9px !important;
        padding: 0.25rem 0.75rem !important; 
    }
}


/* Navbar Oggcanvas */
@media (min-width: 768px) {
    .navbar-light .navbar-brand {
        margin-left: 0;
    }
}


/* Navbar Oggcanvas */
@media (max-width: 991px) {

    button#dropdown-switch-langue {
        padding: 2px 14px;
        width: -webkit-fill-available !important;
        color: #FFF !important;
        margin-top: 22px;
    }
}



@media (min-width: 1200px) {
    .custom-navbar-right.navbar-nav {
        margin-right: -4rem;
    }
    .navbar-nav .nav-item {
        padding: 0 13px;
    }
}

@media (min-width: 1300px) {
    .custom-navbar-right.navbar-nav {
        margin-right: -5rem;
    }
}

@media (min-width: 1400px) {
    .custom-navbar-right.navbar-nav {
        margin-right: 0;
    }
    .custom-navbar.navbar-nav .nav-item {
        margin: 0px 0px 0px 19px;
    }

}


/* .custom-navbar.navbar-nav .nav-item {
    margin: 0px 0px 0px 19px;
} */