.products__filter .products__filter__group .products__filter__header h5 {
  color: #424242 !important;
  line-height: 0px !important;
  font-family: Abril Fatface !important;
}

.products__filter {
  border: 1px solid #707070 !important;
}

/* .btn-check:active+.btn-outline-secondary,  */
.btn-check:focus + .btn-outline-secondary {
  border-color: #0f75ce !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 50%) !important;
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:hover+.btn-outline-secondary
/* .btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active  */ {
  color: #fff;
  background-color: #0f75ce !important;
  border-color: #0f75ce !important;
}

.products__filter .products__filter__group .products__filter__body {
  border-top: 1px solid #707070 !important;
  padding: 1rem 0.5rem 1rem !important;
}

select.form-select,
button.btn.btn-outline-dark {
  border: 1px solid #707070 !important;
  border-radius: inherit !important;
  font-family: 'Rubik' !important;
}

h6.property.range {
  color: #000000;
  font-family: 'Rubik';
  font-weight: 400;
}

button.btn-search.btn.btn-primary {
  background: #0F75CE !important;
  color: #fff;
  font-family: 'Rubik';
  border-radius: initial;
  font-weight: 300;
  text-transform: capitalize;
}

button.btn-search.btn.btn-warning {
  background: #dca554 !important;
  color: #fff;
  font-family: 'Rubik';
  border-radius: initial;
  font-weight: 300;
  text-transform: capitalize;
}

button.btn-search.btn.btn-warning:hover {
  background: #3655b9 !important;
  border: 1px solid #3655b9;
}

.products__filter.sticky-top {
  top: 135px !important;
}

a.btn-invest {
  background: #dca554;
  text-align: center;
  padding: 10px;
  font-family: 'Rubik' !important;
  color: #ffffff;
  width: -webkit-fill-available;
}
a.btn-invest:hover {
  color: #fff !important;
  background: #3655b9 !important;
}

.contact button.btn.btn-link,
a.nav-link.whatsApp {
  border: 1px solid #1c3e88 !important;
  border-radius: inherit !important;
  font-family: Rubik !important;
  color: #1c3e88 !important;
  text-decoration: none;
  padding: 10px;
  font-size: 14px;
  text-align: center;
}
.contact button.btn.btn-link:hover,
a.nav-link.whatsApp:hover {
  background: #1c3e88 !important;
  color: #ffffff !important;
}

button#dropdown-share {
  width: 100%;
}

.d-grid.contact .dropdown-menu.show {
  width: 100%;
  text-align: center;
}

.SimpleShare a {
  padding: 0 10px;
}

/* LOTS FILTER */

label.custom-checkbox {
  font-size: 14px;
  font-family: 'Rubik';
  width: max-content;
  padding: 8px 20px;
  border-radius: inherit;
}

/* custom select2 */

.select__control.css-1s2u09g-control {
  border: 1px solid !important;
  border-radius: inherit !important;
}

.css-6j8wv5-Input {
  z-index: 4;
}

.css-26l3qy-menu {
  z-index: 13 !important;
}