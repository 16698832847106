/* .container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   */
  .slider {
    position: relative;
    width: 100%;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }
  
  .slider__track, .slider__range {
    border-radius: 9px;
    height: 20px;
}
  
  .slider__track {
    background-color: #CBCBCB;
    border: 1px solid #707070;
    width: 100%;
    z-index: auto;
  }
  
  .slider__range {
    background-color: #0F75CE;
    z-index: auto;
  }
  
  .slider__left-value, .slider__right-value {
    color: #1C3E88;
    font-size: 14px;
    margin-top: 40px;
}
  
  .slider__left-value {
    left: 6px;
  }
  
  .slider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  .thumb,
  .thumb::-moz-range-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }
  
  .thumb--left {
    z-index: 1;
  }
  
  .thumb--right {
    z-index: 1;
    right: 0px;
    padding: 0 16px !important;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #0F75CE;
    border: none;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 35px;
    width: 18px;
    margin-top: 17px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #0F75CE;
    border: none;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 35px;
    width: 18px;
    margin-top: 17px;
    pointer-events: all;
    position: relative;
  }
  