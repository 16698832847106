

.fab-container {
  right: 5px;
  z-index: 10;
  bottom: 60px;
  position: fixed;
  cursor: pointer;
}

.fab-icon-holder {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #2872B9;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.fab-icon-holder:hover {
  opacity: 0.8;
}

.fab-icon-holder i {
  display: flex;
  height: 100%;
  font-size: 25px;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}

.fab-options {
  right: 1.6rem;
  bottom: 6.5rem;
  margin: 0;
  opacity: 0;
  position: absolute;
  transform: scale(0);
  list-style-type: none;
  transition: all 0.3s ease;
  transform-origin: 85% bottom;
}

.fab:hover + .fab-options,
.fab-options:hover {
  opacity: 1;
  transform: scale(1);
}

.fab-options li {
  display: flex;
  padding: 10px 5px;
  justify-content: flex-end;
}

.fab-options a {
  text-decoration: none;
}

.fab-label {
  font-size: 16px;
  padding: 2px 10px;
  user-select: none;
  color: #ffffff;
  align-self: center;
  border-radius: 3px;
  margin-right: 10px;
  background: #2872B9;
  white-space: nowrap;
}

.fab-label:after {
  background-color: #2872B9 !important;
  content: '';
  position: absolute;
  background: #2872B9;
  margin-top: 6px;
  width: 10px;
  height: 10px;
  z-index: 1;
  transform: rotate(-45deg);
  right: 60px;
}


/* can i help you */

  /* custom call center  */
  img.custom-call-operator {
    height: 2.3rem;
  }

.fab-btn-call-center {
  background-color: #EAA313 !important;
  align-items: center;
  border-radius: 50%;
  bottom: 2rem;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  position: fixed;
  right: 2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 60px;
}

.can-i-help-you {
  background-color: #EAA313 !important;
  background: #EAA313;
  color: #ffffff !important;
  padding: 3px 10px;
  border-radius: 5px;
  text-decoration: none;
  position: fixed;
  font-family: 'Rubik';
  font-size: 15px;
  /* left: -15rem; */
  bottom: 3rem;
  right: 6.5rem;
}

.can-i-help-you:after {
  background-color: #EAA313 !important;
  content: '';
  position: absolute;
  background: #EAA313;
  right: -5px;
  top: 50%;
  margin-top: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
  transform: rotate(-45deg);
}


/* custom img invest */
img.invstment {
  width: 2.3rem;
  margin-top: 1rem;
  margin-left: 0.3rem;
}

/* custom caculat roi icon*/

svg.calculate-roi {
  width: 2.3rem;
  margin-top: 0.8rem;
  margin-left: 0.4rem;
}


/* btn whatsapp */

.fab-options .styles-module_whatsappButton__IWx9V {
  background-color: #2872B9 !important;
  top: 9.6rem !important;
  right: 0.4rem;
  width: 50px !important;
  height: 50px !important;
}

.fab-options .styles-module_whatsappChatBox__P75uz {
  bottom: 0rem;
  /* right: 0rem; */
}

.fab-options .add-floting-text-in-whatsapp-btn {
  background: #2872B9;
  left: -13rem;
}
.fab-options .add-floting-text-in-whatsapp-btn:after {
  background: #2872B9;
}

