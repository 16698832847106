
.bg-theme-footer {
    background-image: url(../../images/backgroud-footer.png) !important;
}

.bg-theme-footer:before {
    background-color: transparent !important;
}

.wrapper__footer .widget__footer .footer-title {
    font-family: 'Rubik' !important;
    font-size: 22px !important;
}

.wrapper__footer .logo-footer {
    width: 180px !important;
    top: -37px;
    right: 42px;
}

.wrapper__footer .widget__footer ul li span {
    color: #E7E7E7;
    font-weight: normal;
    font-family: 'Rubik';
    font-size: 16px;
}
.wrapper__footer .custom-first-widget__footer p {
    font-size: 16px !important;
    font-family: 'Rubik' !important;
    color: #FFF !important;
    /* text-align: justify; */
    position: relative;
    top: -45px;
}

.btn-social {
    background-color: #0F75CE !important;
    padding: 4px !important;
}
.btn-social:hover {
    background-color: #EAA313 !important;
    border-color: #EAA313 !important;
    color: #FFF !important;
}


.bg__footer-bottom-v1 {
    background-color: #00194F !important;
    border-top: 1px solid #00194F !important;
}

.wrapper__footer .widget__footer ul li {

    padding: 5px 0px 0px 0px !important;
}
.wrapper__footer .widget__footer ul li b {
    color: #FFFFFF !important;
    margin-right: 8px;
}


.wrapper__footer .widget__footer .menu-footer li a{
    color: #E7E7E7;
    font-family: "Rubik" !important;
    font-size: 16px;
}

ul.contact-information {
    width: 20rem;
}

.bg__footer-bottom-v1 span, .bg__footer-bottom span {
    color: #D4D4D4 !important;
    font-size: 16px !important;
    font-family: 'Rubik' !important;
}