
/* custom btn */
.styles-module_floatingWhatsapp__1akvz.custom-class {
  z-index: 20;
}

.styles-module_chatBody__3SG9H .styles-module_message__2RbY_ {
  max-width: max-content !important;
}

.add-floting-text-in-whatsapp-btn {
  background: #EAA313;
  color: #ffffff !important;
  padding: 3px 10px;
  border-radius: 5px;
  text-decoration: none;
  position: absolute;
  font-family: 'Rubik';
  font-size: 15px;
  left: -15rem;
}

a.add-floting-text-in-whatsapp-btn:after{
  content: '';
  position: absolute;
  background: #EAA313;
  right: -5px;
  top: 50%;
  margin-top: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
  transform: rotate(-45deg);
}

.styles-module_whatsappButton__IWx9V:after {
  animation-delay: unset !important;
  animation-duration: unset !important;
}
.styles-module_whatsappButton__IWx9V {
  background-color: #EAA313 !important;
} 

