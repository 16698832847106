@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: Rubik;
}


.navbar-soft .navbar-nav .nav-item .nav-link {
  padding: 0rem 0.5rem !important;
  font-weight: 700;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-400 {
  font-weight: 400 !important;
}

.progress {
  background-color: #929292 !important;
  border-radius: 1.25rem !important;
}

.progress-bar {
  background-color: #0f75ce !important;
}

.font-heading {
  font-family: Abril Fatface !important;
}

.text-gray {
  color: #424242 !important;
}

.text-blue {
    color: #1C3E88 !important;

}
p {
  font-family: Rubik !important;
  font-weight: normal !important;
  font-size: 16px !important;
}

.font-base {
  font-family: 'Rubik' !important;
}

button.btn-base-primary.btn.btn-primary, .base-navlink {
  padding: 0.375rem 0.75rem;
  color: #ffffff;
  text-decoration: none;
  background: #3655B9;
  border-color: #3655B9;
  font-family: Rubik;
  border-radius: inherit;
  height: fit-content;
}

.base-navlink:hover {
    color: #FFF;
    text-decoration: none;
}

button.btn-base-warning.btn.btn-warning {
  padding: 0.375rem 0.75rem;
  color: #ffffff;
  text-decoration: none;
  background: #EAA313;
  font-family: Rubik;
  border-radius: inherit;
  font-weight: 300;
  height: fit-content;
}

button.custom-btn.btn.btn-danger {
  padding: 0.375rem 0.75rem;
  color: #ffffff;
  text-decoration: none;
  background: #dc3545;
  font-family: Rubik;
  border-radius: inherit;
  font-weight: 300;
  height: fit-content;
}

button.custom-btn.btn.btn-success {
  padding: 0.375rem 0.75rem;
  color: #ffffff;
  text-decoration: none;
  background: #1cde6d;
  font-family: Rubik;
  border-radius: inherit;
  font-weight: 300;
  height: fit-content;
}

.custom-z-index {
  z-index: 12;
}


/* custm spinner */

.custm-spinner.spinner-grow {
  margin: 10% 0 10% 50%;
  /* color: #1C3E88 !important; */
}


.wrap-notfound.bg-theme-v8 {
  background-image: url(./images/notfound.jpg);
  width: 100%;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
}