.card__image__header::before {
    background: transparent !important;
}
.card__image__header img.img-transition {
    object-fit: fill;
    height: 396px !important;
}

.info {
    background: #0F75CE;
    color: #FFF;
    padding: 0 10px;
}

li.custom-list-group-item.projet-details.finance.list-group-item {
    padding-top: 2px !important;
}

li.custom-list-group-item.projet-details.list-group-item {
    padding: 12px 0 !important;
    font-size: 16px !important;
}
.card__image__body span {
    font-family: 'Rubik' !important ;
}

.card__image {
    border: none !important;
}
.card__image:hover {
    box-shadow: 0px 0px 0px 0px rgb(19 19 28 / 12%) !important;
}


img.surface {
    width: 39px;
    padding-right: 9px;
    height: auto;
}

@media (max-width: 767px) {
    .card__image__header img.img-transition {
        object-fit: fill;
        height: 250px !important;
    }
}