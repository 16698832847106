
.container.custom-container {
    margin-top: 5rem !important;
}

.custom-checkbox.lots {
    min-width: 114px !important;
}

.parcelle-num {
    color: #0F75CE;
}

/* LotDetails */

.card__image__header::before {
    background: transparent !important;
}

@media  (min-width: 453px) and (max-width: 767px) {

    .custom-checkbox.lots {
    min-width: 200px !important;
}

h6.investment-status.lots {
    padding: 20px 0px !important;
}

}
