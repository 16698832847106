.tabs__custom-v2 .nav-pills {
    border: 1px solid #707070 !important;
}

.tabs__custom-v2 .nav-pills .nav-item .active {
    border: none !important;
}

.tabs__custom-v2 .nav-pills .nav-item .nav-link:hover {
    color: #000 !important;
}

.total-project {
    font-family: 'Abril Fatface' !important;
    font-size: 18px !important;
}

.custom-card-footer {
    background: #0F75CE !important;
    text-align: center !important;
    font-family: 'Rubik';
    font-size: 15px;
    font-weight: 100;
}

.custom-btn-remover.card-footer {
    background: #db1314c7 !important;
    text-align: center !important;
    font-family: 'Rubik' !important;
    font-size: 15px !important;
    font-weight: 100 !important;
}

.custom-btn-success.card-footer {
    background: #10c543c7 !important;
    text-align: center !important;
    font-family: 'Rubik' !important;
    font-size: 15px !important;
    font-weight: 100 !important;
}

.custom-btn-primary.card-footer {
    background: #1C3E88 !important;
    text-align: center !important;
    font-family: 'Rubik' !important;
    font-size: 15px !important;
    font-weight: 100 !important;
}

.custom-list-group-item {
    padding: 5px 0 !important;
}

.custom-list-group-item.list-group-item {
    border: none !important;
    font-family: 'Rubik';
    font-size: 13px;
    font-weight: normal;
    color: #424242 !important;
}

.custom-subtile.card-text {
    text-align: end;
    width: fit-content;
    float: right;
    font-family: 'Rubik';
    /* font-size: 12px !important; */
    font-weight: 400;
    padding: 2px 5px;
}

.card-img-overlay {
    height: fit-content;
}

.card-text:last-child {
    height: fit-content;
}

.superficie {
    background: #1C3E88;
    color: #FFF;
    padding: 0 12px;
}


button.custom-btn-modal {
    font-family: Rubik;
    font-size: 13px;
}

div#example-modal-sizes-title-lg {
    font-family: 'Abril Fatface';
}


/* Modal */

h6.investment-status {
    padding: 20px 13px;
    font-family: 'Abril Fatface';
    color: #000;
    font-weight: normal;
}

label.custom-checkbox {
    font-size: 14px;
    font-family: 'Rubik';
    min-width: -webkit-fill-available;
    min-width: -moz-available;
    width: max-content;
    padding: 8px 20px;
    border-radius: inherit;
}

label.custom-checkbox.localite {
    min-width: 160px;
}
 .card-img-top::before {

    background: aquamarine;
}

img.card-img-top.image-projet {
    height: 250px;
}

img.card-img-top.image-projet::before {
   background: #1C3E88
}

.cursor {
    cursor: pointer;
}


@media screen and (min-width: 320px) and (max-width: 575px){
    .tabs__custom-v2 .nav-pills .nav-item .nav-link {
        padding: 6px 12px !important;
    }
}


button#dropdown-autoclose-true\ sort-by {
    background-color: transparent !important;
    border-color: transparent !important;
    color: black !important;
}




/* Ribbon */

p.bg-primary.text-white.text-uppercase.custom-subtile.ribbon.card-text {
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
    transform: translate3d(0, 0, 1px) rotate(-45deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 6px;
    position: relative;
    z-index: 8;
    left: -52px;
    top: 10px;
    width: 170px;
    text-align: center;
    margin: auto;
    height: 31px;
    bottom: inherit;
    right: inherit;
}