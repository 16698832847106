span.step-title.mr-1 {
    color: #8F8A8A;
    padding: 0 5px;
    border: 1px solid #8F8A8A;
    border-radius: 50%;
}

/* personal informations */
select.custom-select-reservation.form-select {
    border-bottom: 1px solid #707070 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    padding: 1rem 2.25rem 0.375rem 0 !important;
}

.cursor-none {
    cursor: not-allowed;
}

.invst-project-details.list-group-item {
    font: normal normal normal 16px/24px Rubik !important;
    letter-spacing: 0px !important;
    color: #424242 !important;
    margin-left: -1rem !important;
}

.investment-offer-details .form-check-condition {
    margin: 2rem 0 !important;
}

.investment-offer-details .list-group-flush>.list-group-item {
    border-width: 0 !important;
}

.react-tel-input .form-control {
    width: -webkit-fill-available !important;
    width: -moz-available;
}

@media (width >= 768px) {
    .investment-offer-details .form-check-condition {
        margin: 7rem 0 !important;
    }
}


.investment-offer-details .was-validated .form-check-input:invalid~.form-check-label {
    color: #424242 !important;
}