.project-details.nav{
    flex-wrap: nowrap !important;
    white-space: nowrap;
}
.project-details.nav.nav-pills .nav-link {
    font-family: Abril Fatface !important;
    border-radius: inherit !important;
    padding: 0 0 1px 0 !important;
    color: #424242 !important;
    font-size: 18px !important;
}

.project-details.nav .nav-link.active, .nav-pills .show>.nav-link {
    color: #1C3E88 !important;
    background-color: transparent !important;
    border-bottom: 1.5px solid #1C3E88 !important;
    width: fit-content;

}

.nav-link:hover {
    cursor: pointer !important;
}

.detail-heading {
    font-family: Abril Fatface !important;
    font-size: 18px !important;
    border-bottom: 1px solid #707070 !important;
    color: #424242;
}

.single__detail-features h5 {
    color: #424242 !important;
}

.single__detail-features, .single__detail-desc {
    padding-bottom: 20px;
}

.home__video-area .play-video-1 {
    background-color: #F2B459 !important;
}

.single__detail-features-video {
   border: none !important;
    padding: 0 !important;
}

img.carousel-image {
    height: 445px !important;
}
span.carousel-control-next-icon {
    background-color: #DCA554 !important;
}

span.carousel-control-prev-icon {
    background-color: #DCA554 !important;
}

img.video-image {
    height: 445px !important;
    width: 100% !important;
}

.single__detail-features-nearby .list-unstyled li p {
    text-align: left !important;
    color: #1C3E88 !important;
    margin-left: 12rem !important;
}

.list-unstyled.nearby li span {
    font-family: 'Rubik' !important;
    color: #424242 !important;
    font-size: 14px !important;
}

.single__detail-features-review .media .media-body {
    padding: 17px;
}

.single__detail-features-review .media .media-body span {
   font-weight: normal !important;
    line-height: 40px !important;
    letter-spacing: 2px !important;
}


/* investment */

.property__detail-info-list li {
    font-family: 'Rubik' !important;
    color: #424242 !important;
}

.property__detail-info-list li b {
    font-weight: 500 !important;
    padding-right: 8px !important;
}


.documents-details-projet, .documents-details-projet a {
    color: #1C3E88 !important;
    font-weight: 500;
    font-family: 'Rubik';
    font-size: 14px;
}

/* budget */

.single__detail-features-budget {
    border: 1px solid #707070;
}

.single__detail-features-budget .list-group-item {
    border-top: 1px solid #8F8A8A !important;
    padding: 13px 28px;
}
/* .list-group-flush>.list-group-item {
    border-width: 1px 0 1px;
    padding: 13px 28px;
} */
.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0 !important;
}

/* Package investment */
.investment-package.card .card-title.h5 {
    padding-bottom: 5px
}


.investment-package.card .card-subtitle.h6 {
    padding-bottom: 8px;
    font-weight: 400;
}

.investment-package.card-body {
    padding: 1rem 0.5rem !important;
}

.custom-scoroll {
    height: 85px;
    max-height: 85px;
   overflow-y: scroll;
}

p.valeur-estime.card-text {
    font-size: 13px;
}

button.read-more.btn.btn-link {
    text-align: center;
    color: #1C3E88;
    font-family: Rubik;
    font-weight: 500;
    font-size: 13px;
}

.fade.toast.custom-toast.add-pack.show {
    position: fixed !important;
    top: 0 !important;
    width: 39%;
    left: 22rem;
    z-index: 2000;
    text-align: center;
}
@media  (max-width: 768px) {
    .fade.toast.custom-toast.add-pack.show {
        width: 88%;
        left: 0rem;
        top: 10px !important;
        margin: 10px;
    }
}
/* Faq */
.accordion-button:not(.collapsed) {
    color: #424242 !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.accordion-header span {
    font-family: 'Abril Fatface' !important;
    font-style: normal;
    font-size: 19px !important;
    font-weight: 200 !important;
    color: #424242 !important;
}

.accordion-flush .accordion-item .accordion-button {
    font-size: 18px;
    width: fit-content;
}

.accordion-item {
    border: none;
}


.accordion-button::after {
    margin-left: 11px !important;
    position: relative;
    top: -9px;
}

/* timeline */

.timeline {
    position: relative;
}
.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #707070;
    top: 0rem;
    bottom: 0;
    left: 6px;
    opacity: 0.5;
}

.container-timeline {
    padding-bottom: 10px;
    position: relative;
}

.container-timeline::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    background-color: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 50%;
    z-index: 1;
    left: -2px;
    top: 9px;
}

h6.timeline-title {
    font-size: 13px;
    font-family: 'Rubik';
}

span.timeline-description {
    font-family: 'Rubik';
    font-size: 13px;
}
.documents-progress span{
    font-size: 13px;
    font-weight: 500;
    font-family: Rubik;
    color: #1C3E88
}


/* modal alert  */

.modal-content {
    border-radius: initial !important;
}
p.custom-text-alter.text-center.p-4 {
    font-size: 16px !important;
    line-height: 1.9 !important;
}

