/* login */
.card.login {
    border: 1px solid #707070 !important;
    border-radius: inherit !important;
}

.form-login .form-control {
    border-radius: inherit;
    /* border-color: #707070; */
}
.form-login button.btn.btn-primary {
    border-radius: inherit;
}


.form-check-label {
    margin-bottom: 0;
    font-family: Rubik;
    font-size: 15px;
    color: #424242;
}

.form-register .form-control {
    padding: 0.375rem 0rem !important;
    border: none !important;
    border-bottom: 1px solid #707070 !important;
    border-radius: inherit !important;
    height: 32px !important;
    font-size: 14px !important;
}

button.btn-register.btn.btn-primary, .notifation.btn-return {
    padding: 0.375rem 0.75rem;
    color: #FFFFFF;
    text-decoration: none;
    background: #0F75CE;
    font-family: Rubik;
    border-radius: inherit;
    font-weight: 300;
}

p.text-notification {
    line-height: 28px;
}


.was-validated .form-check-input:valid~.form-check-label {
    color: #424242 !important;
}

.was-validated .form-check-input:valid {
    border-color: #424242 !important;
}

.error-message {
    font-size: 12px;
    font-family: Rubik;
}


.form-label {
    margin-bottom: 0rem !important;
    font-size: 13px !important;
  }