
.navbar {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    height: 70px;
}

.banners {
    background: #2872B9 0% 0% no-repeat padding-box;
    font-family: 'Rubik', sans-serif;

}

.customNavLink {
    /* font: normal normal medium 20px/24px Rubik !important; */
    font-family: 'Rubik';
    font-weight: medium;
    font-style: normal;
    letter-spacing: 0px;
    color: #8F8A8A !important;
}