.custom-modal-alert .modal-dialog {
    width: fit-content;
}

/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
    width: 0;
}

.range-slider.single-thumb {
    height: 23px !important;
    background: #CBCBCB 0% 0% no-repeat padding-box;
    border-radius: 24px;
}

.single-thumb .range-slider__range {
    height: 23px !important;
    background: #0F75CE 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
}


.range-slider .range-slider__thumb {
    width: 24px;
    height: 40px;
    border-radius: initial !important;
    background: #0F75CE;
}

button.custom-btn-invest.btn.btn-primary {
    background: #0F75CE !important;
}


.custom-ranger-slider .current-value-checked {
    position: relative;
    background: #0F75CE 0% 0% no-repeat padding-box;
    border-radius: 7px;
    width: fit-content;
    color: #FFF;
    padding: 2px 10px;
    font-size: 15px !important;
}
